<template>
  <div class="trips-open">
   <main-nav />
    <main-titlebar myclass="purpleBar" rbutton="newtrip"/>

    <div class="list-toolbar">
      <div class="" style="float:left;"  >
        <input type="text" v-model="search" class="form-input" @input="evt=>search=evt.target.value">
      </div>
      <div class="list-toolbar-button" style="float:right;"  @click="toggleListView()">
        <div v-if="curListView == 'c'"><i  class="fa-solid fa-border-all"></i></div>
        <div v-if="curListView == 'd'"><i  class="fa-solid fa-bars"></i></div>
      </div>
    </div>
    <!-- Tab content -->
    <div id="active" class="tabcontent" style="display:block;">
      <div class="trip-list-wrapper">
        <div v-for="trip in activeFiltered" :key="trip.id" @click="viewTrip(trip.id)" >
        <!-- <div v-for="trip in trips_active" :key="trip.id" class="trip-row-wrapper" @click="viewTrip(trip.id)"  > -->
          <div class="trip-row" :class="{blueBar: trip.driver == loggedinUser() , orangeBorder: trip.public == 1 }">
            <div v-if="curListView == 'd'"  class="trip-col2 textAlignLeft"><i class="fa-solid fa-circle"></i> {{ statusFriendly(trip.status)}} </div>
            <div class="trip-col1 textAlignLeft"><i class="fa-solid fa-clock"></i> {{ formatDateTime(trip.etd) }}</div>
            <div class="trip-col1 textAlignRight"><i class="fa-solid fa-people-group"></i>{{ trip.passengers}}</div>
            <!-- default view -->
            <div v-if="curListView == 'd'"  class="trip-col2 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ trip.origin_address}} </div>
            <div v-if="curListView == 'd'"  class="trip-col2 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ trip.destination_address}}</div>
            <!-- compact view -->
            <div v-if="curListView == 'c'"  class="trip-col1 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ trip.origin_address}} </div>
            <div v-if="curListView == 'c'"  class="trip-col1 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ trip.destination_address}}</div>
            <!-- only show in default view -->
            <div v-if="curListView == 'd'" class="trip-col1 textAlignLeft"><i class="fa-solid fa-hand-holding"></i> {{ trip.provider_name}}</div>
            <div v-if="curListView == 'd'" class="trip-col1 textAlignRight"><i class="fa-solid fa-car-side"></i> {{ trip.driver_name}}</div>
            <div v-if="curListView == 'd'" class="trip-col2 textAlignLeft"><i class="fa-solid fa-comment"></i> {{ trip.note}}</div>
          </div>
        </div>
      </div>
    </div>

    <div id="history" class="tabcontent">
      <div class="trip-list-wrapper">
        <div v-for="tripa in historyFiltered" :key="tripa.id" @click="viewArchivedTrip(tripa.id)">
        <!-- <div v-for="tripa in trips_history" :key="tripa.id" class="trip-row-wrapper" @click="viewArchivedTrip(tripa.id)"  > -->
          <div class="trip-row" :class="{blueBar: tripa.driver == loggedinUser() , orangeBorder: tripa.public == 1 }">
          <div v-if="curListView == 'd'"  class="trip-col2 textAlignLeft"><i class="fa-solid fa-circle"></i> {{ statusFriendly(tripa.status)}} </div>            
           <div class="trip-col1 textAlignLeft"><i class="fa-solid fa-clock"></i> {{ formatDateTime(tripa.etd) }}</div>
              <div class="trip-col1 textAlignRight"><i class="fa-solid fa-people-group"></i>{{ tripa.passengers}}</div>
              <!-- default view -->
              <div v-if="curListView == 'd'"  class="trip-col2 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ tripa.origin_address}} </div>
              <div v-if="curListView == 'd'"  class="trip-col2 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ tripa.destination_address}}</div>
              <!-- compact view -->
              <div v-if="curListView == 'c'"  class="trip-col1 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ tripa.origin_address}} </div>
              <div v-if="curListView == 'c'"  class="trip-col1 textAlignLeft"><i class="fa-solid fa-crosshairs"></i> {{ tripa.destination_address}}</div>
              <!-- only show in default view -->
              <div v-if="curListView == 'd'" class="trip-col1 textAlignLeft"><i class="fa-solid fa-hand-holding"></i> {{ tripa.provider_name}}</div>
              <div v-if="curListView == 'd'" class="trip-col1 textAlignRight"><i class="fa-solid fa-car-side"></i> {{ tripa.driver_name}}</div>
          </div>
        </div>
      </div>
    </div>


    <div id="agenda" class="tabcontent">
      <div class="trip-list-wrapper">
        <div> Vandaag</div>
        <div> Morgen</div>
      </div>
    </div>


    <div class="tab">
      <button id="tab-active" class="tablinks active" @click="openTab( 'active')"><i class="fa-solid fa-clock"></i> Actueel</button>
      <button id="tab-history" class="tablinks" @click="openTab( 'history')"><i class="fa-solid fa-clock-rotate-left"></i> Historie</button>
      <button id="tab-agenda" class="tablinks" @click="openTab( 'agenda')"><i class="fa-solid fa-calendar"></i> Agenda</button>
    </div>
  </div>
</template>
<script>

import {ref} from 'vue'
import TripService from "../../services/trip-service";

export default {
  name:'trips-my',
   data() {
    const search = ref('')
    var status = 'loading'
    var trips_active = [];
    var trips_history = [];
    var curListView = "d";
    return { trips_active, trips_history,search, status,curListView }
  },
  methods: {
    viewTrip(id) {
      this.$router.push('/trips/view/' + id);
    },
    viewArchivedTrip(id) {
      this.$router.push('/trips/archived/view/' + id);
    },
    toggleListView() {
        console.log("toggleListView: " + this.curListView);
        if(this.curListView == "d") {
          this.curListView = "c";
        } else {
          this.curListView = "d";
        }

    }
  },
  
  mounted() {
    var targ = this;
    // targ.openTab("list");
    TripService.getMyTrips().then(
      (response) => {
        console.log("getMyTrips data loaded from: ");
        targ.status = response.status;
        if(response.data != null) {

          targ.trips_active = response.data;

        }
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    TripService.getMyTripHistory().then(
      (response) => {
        console.log("getMyTripHistory data loaded from: ");
        targ.status = response.status;
        if(response.data != null) {

          targ.trips_history = response.data;
        }

      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

  },
  computed: {
    activeFiltered() {
      return this.trips_active
        .filter(
          (entry) => this.trips_active.length
            ? Object.keys(this.trips_active[0])
                .some(key => ('' + entry[key]).toLowerCase().includes(this.search))
            : true
        );
    },
    historyFiltered() {
      return this.trips_history
        .filter(
          (entry) => this.trips_history.length
            ? Object.keys(this.trips_history[0])
                .some(key => ('' + entry[key]).toLowerCase().includes(this.search))
            : true
        );
    }
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  }
}
</script>